<template>
  <div :style="{'width': fieldWidth}" class="form-group large-input-field" :class="labelClass">
    <h3 v-if="fieldTitle" class="form-input-title">
      {{ fieldTitle }}
      <span v-if="inlineSubtitle" class="inline-subtitle">{{ inlineSubtitle }}</span>
    </h3>
    <div class="input-row">
      <label :for="fieldName" class="input-field-label">

        <!-- The input field images. Two images can be used; one for error and one for normal state of the input field. -->
        <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
        <InlineSvg v-if="inputIcon" class="input-icon" :src="inputIcon" :class="{ 'error': apiError.length > 0 || errorMessages[0], 'contains-input': String(input).length > 0 }" />
        <!-- <img v-if="inputIcon" class="input-icon" :src="inputIcon" alt="Input icon"/> -->

        <!-- The input field. -->
        <textarea
          :id="fieldName"
          ref="inputField"
          v-model="input"
          rows="5"
          cols="33"
          :type="type"
          :name="fieldName"
          :autocomplete="autocomplete ?? fieldName"
          :class="[{'field-error': apiError || errorMessages.length > 0}, {'read-only-input': isReadOnly}, {'no-icon': !inputIcon}, {'input-full-width': inputFullWidth}]"
          :placeholder="placeholder"
          :data-cy="cySelector || `${fieldName}-input`"
          :readonly="isReadOnly"
          class="form-input input-field-textarea"
          :maxlength="maxLength"
          @input="onInput"
          />

        <!-- The extra input error icon. -->
        <img v-if="apiErrorText || errorMessages[0]"
             class="input-error-icon"
             :style="'right: ' + errorIconOffset"
             src="../../assets/icons/icn_error.svg"
             alt="Error icon"/>
      </label>
      <div class="right-side-content">
        <slot name="right-side-content"/>
      </div>
    </div>

    <!-- Extra slot for any custom content. -->
    <slot name="extra-content"/>

    <!-- The errors of the input. -->
    <div v-show="(apiError || errorMessages.length > 0)">
      <!-- give priority to api error msg over vue validator error msg, until user inputs again -->
      <p v-if="apiError && displayApiErrors" class="text-error">{{ apiError }} <slot name="additionalApiErrorSlot"></slot></p>
      <!-- <p v-for="(error, index) of errorMessages" v-else :key="index" class="text-error">{{ error }}</p>-->
      <p v-if="displayFieldErrors && errorMessages" class="text-error">{{ errorMessages[0] }}</p>
    </div>
  </div>
</template>

<script>
import { onUnmounted, ref, watch } from 'vue'
// import {ref, watch} from '@vue/composition-api';
import Validator from '@/utils/yo-validator/YoValidator'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'LargeInputField',
  components: {
    InlineSvg
  },
  props: {
    // required
    fieldTitle: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    // not required
    apiErrorText: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    bindValue: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    rules: {
      type: String,
      required: false,
      default: 'required'
    },
    fieldWidth: {
      type: String,
      default: '100%'
    },
    debounce: {
      type: Number,
      required: false,
      default: 0
    },
    callback: {
      type: Function,
      required: false,
      default: () => {}
    },
    onUpdate: {
      type: Function,
      required: false,
      default: () => {}
    },
    cySelector: {
      type: String,
      required: false,
      default: ''
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    inputIcon: {
      type: String,
      required: false,
      default: ''
    },
    inputIconError: {
      type: String,
      required: false,
      default: ''
    },
    inlineSubtitle: {
      type: String,
      required: false,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: null
    },
    // Error controllers
    displayApiErrors: {
      type: Boolean,
      required: false,
      default: true
    },
    displayFieldErrors: {
      type: Boolean,
      required: false,
      default: true
    },
    inputFullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    errorIconOffset: {
      type: String,
      required: false,
      default: '1rem'
    },
    autocomplete: {
      type: String,
      default: null
    },
    maxLength: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    /** validator returned error messages **/
    const { errorMessages, handleInput, setFieldData, formId, fieldId } = Validator.register(props)
    function onInput(value) {
      if (props.max && parseInt(value.target.value) > props.max) {
        input.value = props.max
        value.target.value = props.max
      }
      handleInput(value)


      props.onUpdate(value.target.value)
    }
    /** watch api error text from parent **/
    const apiError = ref('')
    watch(() => props.apiErrorText, (newVal) => {
      apiError.value = newVal.trim()
    })

    onUnmounted(() => {
      Validator.unregister(formId, fieldId)
    })

    /** on created **/
    const input = ref('');
    // if(props.value) {
    //   input.value = props.value
    // }
    (function setValueOnCreate() {
      if (props.value || props.value === 0) {
        input.value = props.value
        setFieldData(props.value)
      }
    })()

    watch(() => props.value, (newVal) => {
      if (props.isReadOnly || props.bindValue) {
        input.value = props.value
        setFieldData(props.value)
      }
    })

    return {
      errorMessages,
      onInput,
      apiError,
      input
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.large-input-field {

}

.input-full-width {
  width: 100%;
}

.right-side-content{
  display: flex;
  align-items: center;
  position: relative;
  //margin-left: 25px;
}

.form-input.no-icon {
  padding-left: rem(16);
}

.form-input{
  resize: both;
  height: rem(100);
}

.input-row {
  display: flex;
  .input-field-label {
  }
}

.input-icon {
  :deep g path {
    fill: var(--blue_light_01);
  }
}

.input-icon.contains-input {
  :deep g path {
    fill: var(--blue_dark_01);
  }
}

.input-icon.error {
  :deep g path {
    fill: var(--red_error);
  }
}
</style>
