<template>
  <div class="inline-button" :style="cssVars" @click="editTrickWords">
    <inline-svg class="inline-button-icon"
                :class="{'flipped' : flipped}"
                :src="icon"
    >
    </inline-svg>
    <div class="inline-button-text">
      {{ text }}
    </div>
  </div>
</template>

<script>

import InlineSvg from 'vue-inline-svg'
import { computed } from '@vue/runtime-core'


export default {
  name: 'InlineButton',
  components: {
    InlineSvg
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    flipped: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      required: false,
      default: 'var(--pink_main)'
    }
  },
  setup(props) {
    const cssVars = computed(() => {
      return {
        '--icon_color': props.iconColor
      }
    })

    return {
      cssVars
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


.inline-button{
  display: flex;
  align-items: center;
  color: var(--blue_link);
  font-weight: 500;
  cursor: pointer;

  :deep g, :deep g path {
    fill: var(--icon_color);
  }

  .inline-button-icon {
    margin-right: 16px;
  }

  .inline-button-text{

}

  .flipped {
    transform: scaleX(-1);
  }
}

.inline-button-icon{

}


</style>
