<template>
  <div class="filter-wrapper" :class="customClass" @click="removeTag(tag)">
    <div class="filter-item" >  {{ tag.name }} </div>
    <div class="filter-cross"> ✕ </div>
  </div>
</template>

<script>

export default {
  name: 'FilterTag',
  components: {},
  props: {
    tag: {
      type: Object,
      required: true
    },
    removeTag: {
      type: Function,
      required: true,
      default: () => {}
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  setup(props) {

  }
}
</script>

<style scoped lang="scss">
  @import "~@/assets/css/_base.variables.scss";
  @import "~@/assets/css/_base.mixins.scss";

.filter-wrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: var(--gray_light_01);
  border-radius: rem(12);
  padding: 4px 12px;
  color: white;
  cursor: pointer;
}

.filter-item {
  //letter-spacing: rem(0.5);
  margin-right: rem(6);
  font-size: rem(13);
  line-height: rem(24);
  font-weight: 500;
}

.red {
  background-color: var(--red_error);
}



</style>
