<template>
  <div :style="{'width': fieldWidth}" class="form-group">
    <h3 v-if="fieldTitle" class="form-input-title">
      {{ fieldTitle }}
    </h3>
    <div class="tag-wrapper">
      <Tag
        v-for="tag in options"
        :key="tag.id"
        :value="tag.name"
        :is-selected="chosenTags.filter((value) => value === tag.id).length === 1"
        class="tag"
        @click="clicked(tag.id)"
      />
    </div>
    <div v-if="errorText" class="text-error">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import Tag from './Tag'

export default {
  name: 'TagSelector',
  components: {
    Tag
  },
  props: {
    // required
    fieldTitle: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    preSelected: {
      type: Array,
      required: false,
      default: () => []
    },
    fieldWidth: {
      type: String,
      default: '100%'
    },
    callback: {
      required: false,
      type: Function,
      default: () => {
      }
    },
    updateParent: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    errorText: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    /** on created **/
    const chosenTags = ref(props.preSelected)
    props.updateParent(chosenTags.value)

    function clicked(event) {
      const index = chosenTags.value.indexOf(event)
      if (index > -1) {
        chosenTags.value.splice(index, 1)
      } else {
        chosenTags.value.push(event)
      }
      props.updateParent(chosenTags.value)
    }

    watch(() => props.preSelected, (curr, prev) => {
      chosenTags.value = curr
    })

    const emitValue = () => {
      props.callback(chosenTags)
    }

    return {

      chosenTags,
      emitValue,
      clicked,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.tag-wrapper {
  display: flex;
  gap: rem(10);
  flex-wrap: wrap;
  justify-content: flex-start;
}
</style>
