<template>
  <div
    :class="isSelected ? 'selected' : 'not-selected'"
    class="tag"
  >
    {{ value }}
  </div>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    value: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    }
  }

}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.tag {
  padding: rem(10) rem(20);
  border-radius: rem(10);
  border: 1px solid rgba(0, 0, 0, 0.144);
  cursor: pointer;
  user-select: none;

  &.selected {
    background-color: #373B52;
    color: white;
  }

  &.not-selected{
    background-color: white;
  }
}
</style>
