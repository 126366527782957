<template>
  <div v-for="(answer,index) in answers" :key="index">
    <LargeInputField
      :field-title="$t('DIAS.ANSWER') + ' ' + (index + 1)"
      :field-name="`answers[${index}]${fieldNameSuffix}`"
      :rules="'required'"
      :type="'text'"
      :value="answer"
      :bind-value="true"
      :placeholder="$t('DIAS.ANSWER_PLACEHOLDER')"
      :on-update="(value) => setAnswer(index,value)"
      class="form-element"
    >
      <template #right-side-content>
        <CorrectOrDelete :start-correct="correct[index]"
                         :show-remove="answers.length > minAnswers"
                         @delete="() => removeAnswer(index)"
                         @correct="(value) => toggleCorrect(index,value)">
        </CorrectOrDelete>
      </template>
    </LargeInputField>
  </div>
  <p v-if="showErrors && noCorrectSet()" class="text-error">{{ $t('DIAS.NO_ANSWER') }}</p>
  <InlineButton v-if="answers.length < maxAnswers"
                :text="$t('DIAS.SELECT_ANSWER.ADD_MORE_ANSWERS')"
                :icon="require('@/assets/icons/icn_add.svg')"
                :icon-color="'var(--blue_link)'"
                @click="addAnswer"
  />
</template>

<script>
import CorrectOrDelete from '@/components/elements/inputFields/CorrectOrDelete'
import LargeInputField from '@/components/yo-form/LargeInputField'
import InlineButton from '@/components/elements/basic/InlineButton'
export default {
  name: 'AnswerText',
  components: {

    LargeInputField,
    InlineButton,


    CorrectOrDelete
  },
  props: {
    initialData: {
      type: Object,
      required: false,
      default: null
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    fieldNameSuffix: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['data', 'verify'],
  data() {
    const initialAnswers = this.initialData ? this.initialData.contentQuestionJson.answers.map(answer => answer.answer) : null
    return {
      minAnswers: 2,
      maxAnswers: 5,
      answers: this.initialData ? initialAnswers : ['', ''],
      correct: this.initialData ? this.getArrayOfCorrectValuesBasedOnIndex(initialAnswers.length, this.initialData.contentAnswerJson.correctAnswer) : [false, false],
    }
  },
  mounted() {
    this.updateAndVerify()
  },
  methods: {
    addAnswer() {
      if (this.answers.length > 5) {
        return
      }
      this.answers.push('')
      this.correct.push(false)
      this.updateAndVerify()
    },
    removeAnswer(index) {
      if (this.answers.length > 2) {
        this.answers.splice(index, 1)
        this.correct.splice(index, 1)
      }
      this.updateAndVerify()
    },
    toggleCorrect(index, value) {
      this.correct[index] = value
      this.updateAndVerify()
    },
    setAnswer(index, value) {
      this.answers[index] = value
      this.updateAndVerify()
    },
    updateAndVerify() {
      const allAnswersFilled = this.answers.every(x => x.trim() !== '')
      const oneCorrect = this.correct.some(x => x)
      const valid = allAnswersFilled && oneCorrect
      this.$emit('verify', valid)
      this.$emit('data', {
        answers: this.answers,
        correct: this.correct
      })
    },
    noCorrectSet() {
      return !this.correct.some(x => x)
    },
    getArrayOfCorrectValuesBasedOnIndex(totalLength, indices) {
      const arr = []

      if (!Array.isArray(indices)) {
        indices = [indices]
      }

      for (let i = 0; i < totalLength; i++) {
        arr.push(indices.some(index => index === i + 1))
      }
      return arr
    }
  },

}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
.text-error{
  margin-top: rem(20);
  margin-bottom: rem(20)
}
</style>
