export function convertDragAndDropInputToAPIFormat(currentText, stopWords, trickWords) {
  const regexBold = new RegExp('<b>.*?<\/b>')
  let paragraphSplit = currentText.split(regexBold)

  for (let i = paragraphSplit.length - 1; i > 0; i--) {
    paragraphSplit.splice(i, 0, null)
  }
  paragraphSplit = paragraphSplit.filter((p) => {
    if (p === null) {
      return true
    }
    return p.length > 0
  })
  return {
    answer: stopWords.map(x => x.name).concat(trickWords.map(x => x.name)),
    correctAnswer: [...Array(stopWords.length).keys()],
    question: paragraphSplit
  }
}
