<template>
  <div class="correct-or-delete" :class="{'close': close}">
    <div class="correct-toggle" @click="toggleCorrect">
      <check-box :enabled="correct" :radio="single" style="margin-right: 15px;"/>
      <p>{{ $t('CORRECT') }}</p>
    </div>

    <div v-if="showRemove" class="remove" @click="onDelete">
      <inline-svg class="remove-icon"
                  :src="require('../../../assets/icons/icn_delete.svg')" >
      </inline-svg>
      <p>{{ $t('DELETE') }}</p>
    </div>
  </div>
</template>
<script>

import InlineSvg from 'vue-inline-svg'
import CheckBox from '@/components/elements/basic/Checkbox'

export default {
  name: 'CorrectOrDelete',
  components: {
    InlineSvg,
    CheckBox
  },
  props: {
    startCorrect: {
      type: Boolean,
      default: false
    },
    showRemove: {
      type: Boolean,
      default: true
    },
    bind: {
      type: Boolean,
      default: true
    },
    close: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    }
  },
  emits: ['delete', 'correct'],
  data() {
    return {
      correct: this.startCorrect
    }
  },
  watch: {
    startCorrect: function() {
      if (this.bind) {
        this.correct = this.startCorrect
      }
    }
  },
  methods: {
    toggleCorrect() {
      this.correct = !this.correct
      this.$emit('correct', this.correct)
    },
    onDelete() {
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.correct-or-delete {
  position: absolute;
  display: flex;
  left: calc(100% + 30px);
}

.correct-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    margin-right: 15px;
  }
}

.remove {
  display: flex;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;
  color: var(--red_error);
}

.remove-icon {
  margin-right: 10px;
  :deep {
    fill: var(--red_error);
  }
}

</style>
