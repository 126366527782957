<template>
  <inline-svg class="checkbox-icon"
              :src="require(`@/assets/icons/icn_${radio ? 'radio' : 'checkbox'}_${enabled ? 'on' : 'off'}.svg`)" />
</template>

<script>

import InlineSvg from 'vue-inline-svg'

export default {
  name: 'Checkbox',
  components: {
    InlineSvg
  },
  props: {
    enabled: {
      type: Boolean,
      default: false
    },
    radio: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

</style>