<template>
  <h3 v-if="fieldTitle" class="form-input-title">
    {{ fieldTitle }}
    <span v-if="inlineSubtitle" class="inline-subtitle">{{ inlineSubtitle }}</span>
  </h3>
  <div
    class="resizable-area form-input"
    contenteditable="true"
    :class="{error: errors.tooMany.active.value || errors.noInput.active.value}"
    :start-value="value"
    :bind="true"
    @input="getNewMessage"
    v-html="initialData ? initialTextFromData() : value"
  />
  <div v-for="(item, index) in errors" :key="index" class="text-error">
    <div v-if="item.active.value">
      {{ item.message }}
    </div>
  </div>
  <button class="add-stopword-button" type="button" @click="addStopword">
    <img src="@/assets/icons/icn_dragdrop.svg">
    {{ $t('DRAG_AND_DROP.SLEEPWORD') }}
  </button>
  <div v-if="stopWords.length + trickWords.length > 9" class="error-maximum-options"> {{ $t('DRAG_AND_DROP.TOO_MANY_ANSWERS') }} </div>
  <div v-if="stopWords && stopWords.length" class="current-stopword-counter">
    {{ stopWords.length }} sleepwoord{{ stopWords.length > 1 ? 'en' : '' }} geselecteerd
  </div>
  <div
    class="current stopwords">
    <FilterTag v-for="word in stopWords"
               :key="word"
               :tag="word"
               :remove-tag="removeStopWord" />
  </div>

  <div v-if="trickWords && trickWords.length" class="current-stopword-counter">
    {{ trickWords.length }} trucwoord{{ trickWords.length > 1 ? 'en' : '' }} toegevoegd
  </div>
  <div class="incorrect stopwords">
    <FilterTag v-for="word in trickWords"
               :key="word.id"
               :tag="word"
               :custom-class="'red'"
               :remove-tag="() => removeTrickWord(word)"/>
  </div>
  <div class="edit-trick-word" @click="editTrickWords">
    <inline-svg class="edit-icon" :src="require('@/assets/icons/icn_edit.svg')" />
    <div class="edit-text">
      {{ $t("DRAG_AND_DROP.EDIT_TRICK_WORDS") }}
    </div>
  </div>
  <basic-modal v-if="isBasicVersionDisplayed">
    <template #content="">
      <div v-for="index in [0,1,2,3,4]"
           :key="index"
      >
        <InputField
          :field-title="$t('DRAG_AND_DROP.TRICK_WORD') + ` ${index + 1}`"
          :field-name="'titel'"
          :rules="'optional'"
          :inline-subtitle="$t('OPTIONAL')"
          :type="'text'"
          :value="trickWords.find(x => x.id === index)?.name ?? ''"
          :placeholder="$t('DRAG_AND_DROP.TRICK_WORD_PLACEHOLDER')"
          :cy-selector="'name-input'"
          :on-update="(value) => updatedTrickWord(index,value)"
          class="trickword-input"
          style="margin-bottom: 24px;"
        />
      </div>
    </template>
  </basic-modal>
</template>


<script>
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import FilterTag from '@/components/partials/filter/FilterTag'
import InlineSvg from 'vue-inline-svg'
import { useStore } from 'vuex'
import InputField from '@/components/yo-form/InputField'
import { onMounted } from 'vue'

export default {
  name: 'DragAndDropInput',
  components: {
    FilterTag,
    InlineSvg,
    BasicModal,
    InputField
  },
  props: {
    onUpdate: {
      type: Function,
      default: () => {}
    },
    fieldTitle: {
      type: String,
      required: false,
      default: ''
    },
    inlineSubtitle: {
      type: String,
      required: false,
      default: ''
    },
    initialData: {
      type: Object,
      required: false,
      default: null
    }
  },
  setup(props) {
    const MAX_ANSWERS = 9

    const store = useStore()
    const errors = {
      tooMany: {
        message: 'Maximaal 9 antwoordkeuzes bereikt.',
        active: ref(false),
        condition: computed(() => stopWords.value.length + trickWords.value.length >= MAX_ANSWERS)
      },
      noInput: {
        message: 'Voeg sleep- en trucwoorden toe.',
        active: ref(false),
        condition: computed(() => stopWords.value.length === 0 || trickWords.value.length === 0)
      }
    }

    const trickWords = ref([])
    let initialText = ''
    if (props.initialData !== null) {
      loadInitialData(props.initialData)
    }

    const holdingValue = ref(initialText)
    const value = ref(holdingValue.value)
    const stopWords = computed(() => extractStopwords(holdingValue.value).map((tag, index) => {
      return {
        id: index,
        name: tag
      }
    }))


    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.EDIT_TRICK_WORDS
    )

    function loadInitialData(initial) {
      const questions = initial.contentQuestionJson.question
      const answers = initial.contentQuestionJson.answers
      for (let i = 0, correctCounter = 0; i < questions.length; i++) {
        let nextWord = questions[i]
        if (nextWord === null) {
          const correctAnswerIndex = initial.contentAnswerJson.correctAnswer[correctCounter]
          correctCounter++
          nextWord = answers.find(answer => answer.id === correctAnswerIndex).answer
          nextWord = `<b>${nextWord}</b>`
        }
        initialText += nextWord + (i !== questions.length - 1 ? ' ' : '')
      }

      for (let i = 0, j = 0; i < answers.length; i++) {
        if (!initial.contentAnswerJson.correctAnswer.some(correctAnswer => correctAnswer === answers[i].id)) {
          trickWords.value.push({ id: j, name: answers[i].answer })
          j++
        }
      }
    }

    function editTrickWords() {
      modalController.value.setModal(
        BASIC_MODAL_CONTENT.EDIT_TRICK_WORDS,
        () => { modalController.value.resetActiveModal() },
        null,
        null,
        MODAL_NAMES.EDIT_TRICK_WORDS
      )
    }

    function initialTextFromData() {
      const question = props.initialData.contentQuestionJson.question
      for (let i = 0, j = 0; i < question.length; i++) {
        if (question[i] === null) {
          question[i] = '<b>' + props.initialData.contentQuestionJson.answers[props.initialData.contentAnswerJson.correctAnswer[j] - 1].answer + '</b>'
          j++
        }
      }

      return question.join(' ')
    }

    onMounted(sendEvent)

    function stripNonBoldTags(text) {
      // eslint-disable-next-line no-useless-escape
      const regex = new RegExp('<(?!\/?b)[^>]+>', 'gm')
      text = text.replace(regex, '')
      return text
    }


    function removeStopWord(word) {
      const currentText = holdingValue.value
      const boldText = '<b>' + word.name + '</b>'
      const newText = currentText.replace(boldText, word.name)
      holdingValue.value = newText
      value.value = newText
      sendEvent()
    }

    function addStopword() {
      if (errors.tooMany.condition.value) {
        errors.tooMany.active.value = true
        return
      }

      const selected = getSelectionText().trim()
      if (selected === '' || !selected) {
        return
      }
      const currentText = holdingValue.value
      const newText = currentText.replace(selected, '<b>' + selected + '</b>')
      holdingValue.value = newText
      value.value = newText
      sendEvent()
    }

    function updatedTrickWord(index, word) {
      trickWords.value = trickWords.value.filter(value => value.id !== index)
      if (!word || word.trim() === '') {
        return
      }
      trickWords.value.push({ id: index, name: word })
      sendEvent()
    }

    function removeTrickWord(word) {
      trickWords.value = trickWords.value.filter(value => value.id !== word.id)
      sendEvent()
    }

    function strip(html) {
      const doc = new DOMParser().parseFromString(html, 'text/html')
      return doc.body.textContent || ''
    }

    function getNewMessage(e) {
      const text = e.target.innerHTML
      const stripped = stripNonBoldTags(text)
      if (text.toString() !== stripped.toString()) {
        value.value = stripped
      }
      holdingValue.value = stripped
      sendEvent()
    }

    function sendEvent() {
      props.onUpdate(holdingValue.value, stopWords.value, trickWords.value)
    }

    function getSelectionText() {
      var text = ''
      if (window.getSelection) {
        text = window.getSelection().toString()
      // eslint-disable-next-line eqeqeq
      } else if (document.selection && document.selection.type != 'Control') {
        text = document.selection.createRange().text
      }
      return text
    }


    function extractStopwords(message) {
      // eslint-disable-next-line no-useless-escape
      const regex = new RegExp('<b>(.*?)<\/b>', 'ig')
      const matches = message.match(regex)
      if (matches) {
        const strippedMatches = matches.map(value => strip(value))
        return strippedMatches
      }
      return []
    }

    function verify() {
      let success = true
      if (errors.noInput.condition.value) {
        errors.noInput.active.value = true
        success = false
      } else {
        errors.noInput.active.value = false
      }

      errors.tooMany.active.value = false
      return success
    }

    return {
      addStopword,
      removeStopWord,
      stopWords,
      getNewMessage,
      isBasicVersionDisplayed,
      activeModal,
      trickWords,
      editTrickWords,
      updatedTrickWord,
      removeTrickWord,
      verify,
      errors,
      initialTextFromData,
      value
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.form-input{
  margin-top: rem(10);
  resize: both;
  padding-left: rem(16);
  height: rem(100);
}

.highlighted{
  color: green;
  font-weight: bold;
}

.add-stopword-button {
  border-radius: 4px;
  box-shadow: 2px 2px 0 0 rgba(0,0,0,0.1);
  margin: rem(10) 0;
  padding: rem(10);
  background-color: white;
  color: #373B52;
  display: flex;
  align-items: center;
  cursor: pointer;

  font-weight: 500;

  img {
    margin-right: 10px;
  }
}

:deep b{
  color: #4EB5A7;
}

.resizable-area{
  resize: both;
  overflow-y: auto;
  outline: none;

  &.error {
    border: solid 1px var(--red_error);
  }
}

.stopwords{
  margin: rem(10) 0;
  display: flex;
  max-width: rem(500);
  gap: rem(10);
  flex-wrap: wrap;
}

.error-maximum-options{
  color: var(--red_error)
}

.current-stopword-counter {
  color: #7A95B2;
  font-size: 12px;
}


.edit-trick-word {
  display: flex;
  align-items: center;
  color: var(--blue_link);
  font-weight: 500;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  :deep g, :deep g path {
    fill: var(--pink_main);
  }

  .edit-icon{
    transform: rotate(270deg);
    :deep g path {
      fill: var(--blue_link)
    }
  }
}

</style>
