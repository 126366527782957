export const groupData = Object.freeze([
  {
    id: 1,
    name: 'Group 5/6'
  },
  {
    id: 2,
    name: 'Group 5/6 en 7/8'
  },
  {
    id: 3,
    name: 'Group 7/8'
  }
])

/**
 * Format difficulty for API
 * @param {Number} id 
 * @returns Array
 */
export function setGroupDifficulty(id) {
  if (id === 1) return [1];
  if (id === 2) return [1, 2];  
  if (id === 3) return [2];
}

/**
 * Format difficulty for front-end
 * @param {Array} array
 * @returns Object
*/
export function getGroupDifficulty(array) {
  if (array.includes(1) && array.includes(2)) return groupData[1];
  if (array.includes(1)) return groupData[0];
  if (array.includes(2)) return groupData[2];
}
